import axios from "@/axios.js";

export default {
  /**
   * Retrieve updated userInfo
   * @param {*} me Vue's `this` instance
   */
  GetMe() {
    return new Promise((resolve, reject) => {
      axios
        .get("me")
        .then((response) => {
          if (!response) return;

          const UI = response.data.data;
          if (![undefined, "undefined", null, "null"].includes(UI.nexlaw_credits)) localStorage.setItem("userInfo", JSON.stringify(UI));

          resolve(response);
        })
        .catch((error) => { reject(error); });
    });
  },

  /**
   * Retrieve campaign list
   * @param {*} args Queries to filter the list
   */
  GetCampaigns(args = null) {
  let API = `nexlaw`;

  if (args) {
    const queryString = Object.entries(args)
      .map(([k, v]) => Array.isArray(v) 
        ? v.map(item => `${k}[]=${item}`).join('&') // Properly format array parameters
        : `${k}=${v}`
      )
      .join('&');

    API += `?` + queryString;
  }

  return new Promise((resolve, reject) => {
    axios
      .get(API)
      .then((response) => { resolve(response); })
      .catch((error) => { reject(error); });
  });
},

  /**
   * Permanently delete a campaign
   * @param {*} id Campaign ID
   */
  DeleteCampaign(id) {
    return new Promise((resolve, reject) => {
      axios
        .delete(`nexlaw/${id}/delete`)
        .then((response) => { resolve(response); })
        .catch((error) => { reject(error); });
    });
  },

  /**
   * Bookmark campaign
   * @param {*} id Campaign ID
   */
      BookmarkCampaign(id) {
        return new Promise((resolve, reject) => {
          axios
            .post(`nexlaw/${id}/bookmark`)
            .then((response) => { resolve(response); })
            .catch((error) => { reject(error); });
        });
      },

  /**
   * Move campaign into `Archived Campaigns`
   * @param {*} id Campaign ID
   */
  ArchiveCampaign(id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`nexlaw/${id}/archive`)
        .then((response) => { resolve(response); })
        .catch((error) => { reject(error); });
    });
  },

  /**
   * Unarchive campaign
   * @param {*} id Campaign ID
   */
  UnarchiveCampaign(id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`nexlaw/${id}/unarchive`)
        .then((response) => { resolve(response); })
        .catch((error) => { reject(error); });
    });
  },

  SaveChanges(id, item, flag) {
    const APIs = {
      "LWriter": `nexlaw/letter-of-demand/${id}/save-output`,
      "LArg": `nexlaw/legal-argument/${id}/save-data`,
      
      // was used in AiSug v1 @legalwriter & @legalresearch
      // `nexlaw/discussion/${id}/change-content`,
    };

    return new Promise((resolve, reject) => {
      axios
        .post(APIs[flag], item)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  GetLegalWriter(obj) {
    return new Promise((resolve, reject) => {
      axios
        .get(`nexlaw` + obj)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  CreateLOD(item) {
    return new Promise((resolve, reject) => {
      axios
        .post(`nexlaw/letter-of-demand`, item)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  ViewLegalWriter(id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`nexlaw/` + id)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  SummarizeLetter(id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/nexlaw/letter-of-demand/${id}/summarize`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  CreateLegalArgumentMemo(item) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/nexlaw/legal-argument`, item)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  UploadDocument(id, item) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/nexlaw/legal-argument/${id}/analyze-document`, item)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  openDocument(id, docID) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/nexlaw/discussion/${id}/document/${docID}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  SummarizeDocument(id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/nexlaw/legal-argument/${id}/summarize-document`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  alternatives(id, alternate_id) {
    return new Promise((resolve, reject) => {
      axios
        .get(`/nexlaw/discussion/${id}/alternative/${alternate_id}`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  SummarizeAlternate(id) {
    return new Promise((resolve, reject) => {
      axios
        .post(`/nexlaw/legal-argument/${id}/summarize-case`)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  sendQuestion(type, id, item) {
    return new Promise((resolve, reject) => {
      axios
        .post(`nexlaw/case/${type}/${id}/ask`, item)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
}