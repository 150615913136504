<template>
  <section class="sidebar">
    <header class="header">
      <button type="button" class="icon-button" tabindex="0" @click="closeSidebar">
        <span class="material-symbols-rounded me-2">keyboard_double_arrow_right</span>
      </button>
      <div class="header-content">
        <h5 class="header-title">AI Suggestions for Discussions</h5>
        <p class="header-subtitle">Upload a case law file to generate discussion</p>
      </div>
    </header>

    <form class="upload-form" @click="showModal = true">
      <label for="fileInput" class="visually-hidden">Upload File</label>
      <div class="upload-button">
        <input type="file" id="fileInput" class="file-input" aria-label="Upload File" @change="handleFileChange" />
        <button type="button" class="button-blue" tabindex="0">
          <span class="material-symbols-rounded me-2">add</span>
          <span class="button-text">Upload File</span>
        </button>
      </div>
    </form>

    <b-modal v-model="showModal" hide-footer hide-header size="lg">
      <!-- Custom modal header with close button -->
      <div class="custom-header d-flex justify-content-between align-items-center">
        <h4 class="m-0">Upload File</h4>
        <button @click="showModal = false" class="close-btn">
          <span class="material-symbols-rounded">close</span>
        </button>
      </div>

      <div class="mt-4">
        <FileInput :single="true" :accept="['pdf']" @input-files="$set(files, 0, $event)" />
        <FileProcess style="max-height: 200px; overflow-y: auto; margin-top: 1rem;" :accept="['pdf']" :files="files[0]"
          @output-files="$set(files, 1, $event)" :single="true" />
      </div>

      <div v-if="files[1].length" class="mt-2 mb-3">
        <label for="fileName" class="pt mb-2">File Name</label>
        <input type="text" v-model="selectedFileName" id="fileName" class="form-control custom_file"
          placeholder="Please rename your file" />
      </div>

      <div class="d-flex justify-content-end">
        <button class="btn d-flex align-items-center" :class="files[1].length ? 'upload-active' : 'upload'"
          @click="handleFileUpload">
          <span class="material-symbols-rounded me-2">upload</span>Upload
        </button>
      </div>
    </b-modal>

    <section class="uploaded-files">
      <hr class="separator" />
      <p class="uploaded-files-title">Uploaded Files</p>

      <article class="uploaded-file cursor-pointer" @click="goToFilePage">
        <p class="file-title">Smith_v_Jones_2023.pdf</p>
        <p class="file-description text-truncate">
          In the case of Zicarelli v. New Jersey State Comm of
          Investiaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaag...
        </p>
      </article>
    </section>
  </section>
</template>
<script>
import FileInput from './../../components/input/FileInput.vue';
import FileProcess from './../../components/input/FileProcess.vue';
import advice from '../../store/advice';

export default {
  props: {
    id: {
      type: Number,
      required: true,
    },
    discussionId: {
      type: Number,
      required: true
    }
  },
  components: {
    FileProcess, FileInput,
  },
  data() {
    return {
      showModal: false,
      files: [[], []],
      selectedFileName: "",
      uploadedFiles: []
    };
  },
  methods: {
    closeSidebar() {
      this.$emit('closeSidebar');
    },
    goToFilePage() {
      this.$router.push({ name: 'LegalAdvice_Summary' });
    },
    handleFileChange(event) {
      const file = event.target.files[0];
      if (file) {
        this.files[0] = [file]; // Store file in files array
        this.selectedFileName = file.name;
      } else {
        this.files = [[], []]; // Reset files if no file selected
        this.selectedFileName = "";
      }
    },
    handleFileOutput(outputFiles) {
      this.files[1] = outputFiles; // Store output files if needed
      if (this.files[1].length > 0) {
        this.selectedFileName = this.files[1][0].name; // Set file name
      }
    },
    handleFileUpload() {
      const formData = new FormData();
      if (this.files[1].length > 0) {
        formData.append('case_file', this.files[1][0]); // Append the actual file (binary)
      } else {
        this.$toast.error("No file selected for upload");
        return;
      }
      formData.append('case_name', this.selectedFileName); // Append the file name

      advice.UploadDocument(this.discussionId, formData)
        .then(response => {
          this.$toast.success(response.data.message);
          this.$router.push({ name: "LegalCopilot_CaseFlowResult" });
        })
        .catch(error => {
          console.error('File upload failed:', error);
        });
    },
  },
};
</script>

<style scoped>
.form-control {
  font-size: 14px;
}

.sidebar {
  background: #fff;
  box-shadow: 0px 4px 10px 0px rgba(170, 171, 175, 0.6);
  display: flex;
  max-width: 480px;
  width: 100%;
  flex-direction: column;
  overflow: hidden;
  justify-content: flex-start;
  margin: 0 auto;
  padding: 24px;
  height: 100vh;
}

.header {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 16px;
  justify-content: flex-start;
}

.icon-button {
  color: #0E4485;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background: none;
  border: none;
  cursor: pointer;
}

.icon-image {
  width: 32px;
  height: 32px;
}

.header-content {
  display: flex;
  flex-direction: column;
  flex: 1;
  font-family: "Poppins", sans-serif;
}

.header-title {
  font-size: 20px;
  color: #383a3e;
  font-weight: 600;
  margin: 0;
}

.header-subtitle {
  font-size: 12px;
  color: #86888d !important;
  font-weight: 400;
  line-height: 24px;
  margin: 4px 0 0;
}

.upload-form {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  font-family: "Poppins", sans-serif;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.upload-button {
  display: flex;
  align-items: center;
  gap: 10px;
}

.file-input {
  display: none;
}

.button-blue {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: #0e4485;
  color: #fff;
  border-radius: 4px;
  padding: 8px 16px;
  border: none;
  cursor: pointer;
}

.upload-icon {
  width: 24px;
  height: 24px;
}

.button-text {
  margin: 0;
}

.uploaded-files {
  max-height: 300px;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.separator {
  border: 1px solid var(--mid-grey);
}

.uploaded-files-title {
  color: #86888d;
  font-weight: 400;
  margin: 0px;
  padding: 0px 8px;
  font-family: "Poppins", sans-serif;
}

.uploaded-file {
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  padding: 6px 0 6px 6px;
  font-family: "Poppins", sans-serif;
}

.uploaded-file:hover {
  background: #f2f3f3;
}

.file-title {
  font-size: 14px;
  color: #383a3e;
  margin: 0;
}

.file-description {
  font-size: 12px;
  color: #86888d;
}

.loading-button {
  display: flex;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  border: none;
  background: none;
  cursor: pointer;
}

.loading-icon {
  width: 18px;
  height: 18px;
  background: #0e4485;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading-image {
  width: 18px;
  height: 18px;
}

.loading-text {
  color: #0e4485;
  font-weight: 500;
  font-family: "Poppins", sans-serif;
}

.link-button {
  text-decoration: underline;
  color: #0e4485;
  font-weight: 500;
  background: none;
  border: none;
  cursor: pointer;
}

.background-image {
  position: absolute;
  right: 126px;
  top: 109px;
  width: 20px;
  height: 21px;
}

.upload {
  color: #D1D2D5;
  background: #0E44851A;
  width: 118px;
}

.upload-active {
  width: 118px;
  background: #0E4485;
  color: #fff;
}

.upload:hover {
  background: #0E4485;
  color: #fff;
}
</style>
